import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout";
import adminApi from "../../../api/adminApi.js"; // Import the API
import InitialsAvatar from "react-initials-avatar";

interface User {
  id: number;
  name: string;
  email: string;
  avatar?: string;
  phoneNumber: string;
  stores: string[];
  isAdmin: boolean;
  isActive: boolean;
}

const SinglePartnerPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user as User;

  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phoneNumber: user?.phoneNumber || "",
    stores: user?.stores || [],
    img: user?.avatar || "",
    isActive: user?.isActive || false,
  });
  console.log(formData.stores);

  useEffect(() => {
    if (!user) {
      navigate("/admin/dashboard/users"); // Redirect if user not found
    } else {
      const id = window.location.pathname.split("/").pop(); // Assuming user has an 'id' property
      setUserId(id);
    }
  }, [user, navigate]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStoreChange = (index: number, value: string) => {
    const updatedStores = [...formData.stores];
    updatedStores[index] = value;
    setFormData((prevData) => ({
      ...prevData,
      stores: updatedStores,
    }));
  };

  const handleAddStore = () => {
    setFormData((prevData) => ({
      ...prevData,
      stores: [...prevData.stores, ""], // Add an empty store input
    }));
  };

  const handleRemoveStore = (index: number) => {
    const updatedStores = formData.stores.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      stores: updatedStores,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await adminApi.updateUser(userId, formData);
      alert("User updated successfully");
      navigate("/admin/dashboard/partner"); // Redirect after update
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Error updating user");
    }
  };

  return (
    <Layout>
      <div className="flex flex-col gap-12 mt-8">
        <div className="flex-1 bg-[#182237] p-8 rounded-2xl font-bold text-[#b7bac1] h-max">
          <div className="flex items-center mb-4">
            {user?.avatar ? (
              <img
                src={user.avatar}
                alt={`${user.name}'s avatar`}
                width={50}
                height={50}
                className="rounded-full object-cover mr-4"
              />
            ) : (
              <div className="border border-black rounded-full px-4 py-3 mr-4">
                <InitialsAvatar
                  className="text-xl md:text-5xl"
                  name={user?.name}
                />
              </div>
            )}
            <div>
              <h3 className="text-2xl font-semibold text-white">
                {user?.name}
              </h3>
              <p className="text-gray-400">{user?.email}</p>
            </div>
          </div>
        </div>
        <div className="flex-[4] bg-[#182237] p-8 rounded-2xl">
          <form onSubmit={handleSubmit} className="flex flex-col text-[12px]">
            <input type="hidden" name="id" value={user?.id} />
            <label htmlFor="name">Name</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
            />
            <label htmlFor="email">Email</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
            <label htmlFor="phoneNumber">Phone</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
            />
            <label htmlFor="img">Avatar URL</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="img"
              id="img"
              value={formData.img}
              onChange={handleChange}
            />

            <label htmlFor="stores">Stores</label>
            <div className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a]">
              {formData.stores.length > 0 ? (
                formData.stores.map((storeId, index) => (
                  <div key={index} className="flex items-center gap-4 mb-2">
                    <input
                      type="text"
                      value={storeId}
                      onChange={(e) => handleStoreChange(index, e.target.value)}
                      className="w-full p-2 rounded text-white bg-[#2d3e50] border border-[#2e374a]"
                      placeholder="Store ID"
                    />
                    <button
                      type="button"
                      className="text-red-500"
                      onClick={() => handleRemoveStore(index)}
                    >
                      Remove
                    </button>
                  </div>
                ))
              ) : (
                <p>No stores</p>
              )}
            </div>
            <button
              type="button"
              className="w-full p-4 bg-blue-500 text-white rounded-md mb-4"
              onClick={handleAddStore}
            >
              Add Store
            </button>
            <button className="w-full p-8 bg-[teal] text-white border-none rounded-[5px] mt-8 cursor-pointer text-[16px]">
              Update
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default SinglePartnerPage;
