import React, { useState } from "react";
import Layout from "../../layout";
import adminApi from "../../../api/adminApi.js"; // Import the API
import { useNavigate } from "react-router-dom";

const AddPartnerPage: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  const navigate = useNavigate();
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await adminApi.createUser(formData);
      alert("User created successfully");
      navigate("/admin/dashboard/partner");
    } catch (error) {
      console.error("Error creating user:", error);
      alert("Error creating user");
    }
  };

  return (
    <Layout>
      <div className="bg-[#182237] p-8 rounded-2xl mt-8">
        <form
          onSubmit={handleSubmit}
          className="flex flex-wrap justify-between"
        >
          <input
            className="p-12 text-white mb-12 rounded-[5px] bg-[#151c2c] border-2 border-[#2e374a] w-[45%]"
            type="text"
            placeholder="User Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            className="p-12 text-white mb-12 rounded-[5px] bg-[#151c2c] border-2 border-[#2e374a] w-[45%]"
            type="email"
            placeholder="Email Address"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            className="p-12 text-white mb-12 rounded-[5px] bg-[#151c2c] border-2 border-[#2e374a] w-[45%]"
            type="text"
            placeholder="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <button
            className="w-full p-12 bg-[teal] text-white border-none rounded-[5px] cursor-pointer"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default AddPartnerPage;
