import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

interface MapComponentProps {
  setMapLocation: (location: string) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({ setMapLocation }) => {
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [currentPosition, setCurrentPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [markerPosition, setMarkerPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const API = process.env.REACT_APP_GOOGLE_MAP_API as string;
  console.log(map);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const initialPosition = { lat: latitude, lng: longitude };
          setCurrentPosition(initialPosition);
          setMarkerPosition(initialPosition);
          setMapLocation(
            `https://maps.google.com/maps?q=${latitude},${longitude}`
          );
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [setMapLocation]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    if (lat !== undefined && lng !== undefined) {
      const newMarkerPosition = { lat, lng };
      setMarkerPosition(newMarkerPosition);
      setMapLocation(`https://maps.google.com/maps?q=${lat},${lng}`);
    }
  };

  const handleMarkerDragEnd = (event: google.maps.MapMouseEvent) => {
    const lat = event.latLng?.lat();
    const lng = event.latLng?.lng();
    if (lat !== undefined && lng !== undefined) {
      const newMarkerPosition = { lat, lng };
      setMarkerPosition(newMarkerPosition);
      setMapLocation(`https://maps.google.com/maps?q=${lat},${lng}`);
    }
  };

  return (
    <LoadScript googleMapsApiKey={API}>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={currentPosition || { lat: 0, lng: 0 }}
        zoom={15}
        onClick={handleMapClick}
        onLoad={(map) => setMap(map)}
      >
        {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
          />
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapComponent;
