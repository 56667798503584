import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../ui/dashboard/pagination/pagination";
import Search from "../../ui/dashboard/search/search";
import Layout from "../layout";
import adminApi from "../../api/adminApi"; // Adjust the import according to your actual API file
import { FaEye, FaTrash } from "react-icons/fa";
import { Request } from "../../../interface/Request";

const RequestsPage: React.FC = () => {
  const [requests, setRequests] = useState<Request[]>([]);
  const [filteredRequests, setFilteredRequests] = useState<Request[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState<string>(""); // State for status filter
  const ITEMS_PER_PAGE = 10;

  const location = useLocation();
  const navigate = useNavigate();

  const fetchRequests = async () => {
    try {
      const response = await adminApi.getAllRequests(); // Assuming this returns the correct structure
      setRequests(response || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch requests", error);
      setRequests([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page") || "1", 10);
    const query = params.get("q") || "";

    setCurrentPage(page);
    const filterRequests = (query: string) => {
      let filtered = requests.filter((request) =>
        request.requestedUserId.phoneNumber.includes(query.toLowerCase())
      );

      // Filter by status if a status filter is applied
      if (statusFilter) {
        filtered = filtered.filter(
          (request) => request.status === statusFilter
        );
      }

      setFilteredRequests(filtered);
    };
    if (requests.length > 0) {
      filterRequests(query);
    }
  }, [location.search, requests, statusFilter]);

  const handlePageChange = (type: "prev" | "next") => {
    const params = new URLSearchParams(location.search);
    const newPage = type === "prev" ? currentPage - 1 : currentPage + 1;
    params.set("page", newPage.toString());
    navigate(`?${params.toString()}`);
  };

  const displayedRequests = filteredRequests.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleDelete = async (id: string) => {
    try {
      await adminApi.deleteListing(id); // Ensure the delete method uses the correct identifier
      alert("Request deleted successfully");
      fetchRequests(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting Request:", error);
      alert("Error deleting Request");
    }
  };

  return (
    <Layout>
      <div className="bg-[#182237] h-screen p-4 mt-8 rounded-2xl md:p-8">
        <div className="flex flex-row items-center justify-between mb-4 md:mb-8">
          <Search placeholder="Search for a request..." />
          <select
            className="ml-4 p-4 bg-[#5d57c9] rounded border border-gray-300"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">All Statuses</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="canceled">Canceled</option>
          </select>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-left text-gray-400">
            <thead className="bg-[#2d3e50] text-gray-200">
              <tr>
                <th className="py-4 px-6">Shop Image</th>
                <th className="py-4 px-6">Shop Name</th>
                <th className="py-4 px-6">Requested User Name</th>
                <th className="py-4 px-6">Requested User Email</th>
                <th className="py-4 px-6">Requested User Phone Number</th>
                <th className="py-4 px-6">Status</th>
                <th className="py-4 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={7} className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : displayedRequests.length === 0 ? (
                <tr>
                  <td colSpan={7} className="text-center py-4">
                    No requests found.
                  </td>
                </tr>
              ) : (
                displayedRequests.map((request) => (
                  <tr
                    key={request.requestedStoreId?._id}
                    className="border-b border-gray-700 hover:bg-[#2a3646]"
                  >
                    <td className="py-4 px-6">
                      {request.requestedStoreId?.images?.[0]?.img1 ? ( // Added optional chaining
                        <img
                          src={request.requestedStoreId.images[0].img1} // Access images after checking for null
                          alt="Request Owner"
                          className="h-16 w-16 rounded-full object-cover mr-4"
                        />
                      ) : (
                        <div className="h-16 w-16 rounded-full object-cover mr-4 bg-gray-500" />
                      )}
                    </td>
                    <td className="py-4 px-6">
                      {request.requestedStoreId?.shopDetails?.shopName ||
                        "Shop Name Not Found"}
                    </td>
                    <td className="py-4 px-6">
                      {request.requestedStoreId?.ownerName ||
                        "Owner Name Not Found"}
                    </td>
                    <td className="py-4 px-6">
                      {request.requestedStoreId?.ownerEmailAddress ||
                        "Email Not Found"}
                    </td>
                    <td className="py-4 px-6">
                      {request.requestedStoreId?.ownerPhoneNumber ||
                        "Phone Number Not Found"}
                    </td>
                    <td className="py-4 px-6 uppercase">{request?.status}</td>
                    <td className="py-4 px-6 flex items-center gap-4">
                      <Link
                        to={`/admin/dashboard/requests/${request.requestedStoreId?._id}`}
                        state={{ request }}
                      >
                        <button className="text-teal-500 hover:text-teal-600">
                          <FaEye />
                        </button>
                      </Link>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleDelete(request.requestedStoreId?._id); // Ensure deletion targets the correct request ID
                        }}
                      >
                        <button
                          type="submit"
                          className="text-red-500 hover:text-red-600"
                        >
                          <FaTrash />
                        </button>
                      </form>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          count={filteredRequests.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default RequestsPage;
