import React, { useMemo } from "react";
import MenuLink from "./menuLink/menuLink";
import {
  MdDashboard,
  MdSupervisedUserCircle,
  MdLogout,
  MdRequestPage,
} from "react-icons/md";
import { BsShop } from "react-icons/bs";
import InitialsAvatar from "react-initials-avatar";
import { useNavigate } from "react-router-dom";

interface User {
  email: string;
  name: string;
  avatar: string;
  role: string;
}

interface LayoutProps {
  user: User | null;
}

interface MenuItem {
  title: string;
  path: string;
  icon: React.ReactNode;
}

interface MenuCategory {
  title: string;
  list: MenuItem[];
}

const adminMenuItems: MenuCategory[] = [
  {
    title: "Pages",
    list: [
      { title: "Dashboard", path: "/admin/dashboard", icon: <MdDashboard /> },
      {
        title: "Users",
        path: "/admin/dashboard/users",
        icon: <MdSupervisedUserCircle />,
      },
    ],
  },
  {
    title: "Partners",
    list: [
      {
        title: "Partners",
        path: "/admin/dashboard/partner",
        icon: <MdSupervisedUserCircle />,
      },
      { title: "Shops", path: "/admin/dashboard/shops", icon: <BsShop /> },
    ],
  },
  {
    title: "Help",
    list: [
      {
        title: "Requests",
        path: "/admin/dashboard/requests",
        icon: <MdRequestPage />,
      },
    ],
  },
];

const getMenuItemsForUserRole = (userRole: string): MenuCategory[] => {
  return ["Admin", "Rider", "Shop"].includes(userRole) ? adminMenuItems : [];
};

const Sidebar: React.FC<LayoutProps> = ({ user }) => {
  const menuItems = useMemo(
    () => getMenuItemsForUserRole(user?.role ?? ""),
    [user?.role]
  );

  const navigate = useNavigate();
  // Logout function to remove the token and update authentication state
  const logout = () => {
    localStorage.removeItem("accessToken"); // Remove the access token from localStorage
    localStorage.setItem("isAuthenticated", "false"); // Set isAuthenticated to false
    navigate("/");
    window.location.reload();
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  // Early return if the user is not available yet
  if (!user) {
    return (
      <div className="w-72 h-screen bg-[#182237] p-6 flex flex-col items-center justify-center">
        <p className="text-white">Loading...</p>
        <button className="bg-white p-4 rounded-xlu" onClick={handleRefresh}>Refresh Sidebar</button>
      </div>
    );
  }

  return (
    <div className="w-72 h-screen bg-[#182237] p-6 flex flex-col">
      {/* User Profile Section */}
      <div className="flex flex-col items-center mb-8">
        {user.avatar ? (
          <img
            src={user.avatar}
            alt={`${user.name}'s avatar`}
            className="rounded-full w-20 h-20 object-cover mb-3 border-4 border-[#2e374a] shadow-md"
          />
        ) : (
          <div className="bg-gray-600 rounded-full w-20 h-20 flex items-center justify-center mb-3 border-4 border-[#2e374a] shadow-md">
            <InitialsAvatar name={user.name} />
          </div>
        )}
        <div className="text-center">
          <h2 className="text-2xl font-semibold mb-1">{user.name}</h2>
          <p className="text-sm text-gray-400">{user.role}</p>
        </div>
      </div>

      {/* Divider */}
      <hr className="border-gray-600 mb-6" />

      {/* Menu Items */}
      <ul className="space-y-6">
        {menuItems.map((category) => (
          <div key={category.title}>
            <h3 className="text-xs font-bold text-gray-500 uppercase tracking-wider mb-3">
              {category.title}
            </h3>
            {category.list.map((item) => (
              <MenuLink item={item} key={item.title} />
            ))}
          </div>
        ))}
      </ul>

      {/* Divider */}
      <hr className="border-gray-600 mt-8 mb-6" />

      {/* Logout Button */}
      <div>
        <button
          onClick={logout}
          className="flex items-center w-full p-3 text-lg text-white bg-red-600 rounded-lg hover:bg-red-500 transition-all ease-in-out duration-200"
        >
          <MdLogout className="text-2xl mr-3" />
          Logout
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
