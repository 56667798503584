import adminApi from "../admin/api/adminApi.js";

export const getUserDetails = async () => {
  try {
    const response = await adminApi.getMyProfile();
    return response;
  } catch (error) {
    console.error("Error fetching user details:", error);
    return null;
  }
};

export const isAuthenticated = async () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return false;

  try {
    const user = await getUserDetails();
    return !!user; // Return true if user exists, false otherwise
  } catch (error) {
    console.error("Error verifying user authentication:", error);
    return false;
  }
};
