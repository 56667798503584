import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./admin/dashboard/dashboard";
import LoginPage from "./admin/login/page";
import UsersPage from "./admin/dashboard/users/page";
import AddUserPage from "./admin/dashboard/users/add/page";
import SingleUserPage from "./admin/dashboard/users/[id]/page";
import ShopsPage from "./admin/dashboard/shops/page";
import AddShopPage from "./admin/dashboard/shops/add/page";
import SingleShopPage from "./admin/dashboard/shops/[id]/page";
import PartnerPage from "./admin/dashboard/partner/page";
import AddPartnerPage from "./admin/dashboard/partner/add/page";
import SinglePartnerPage from "./admin/dashboard/partner/[id]/page";
import RequestsPage from "./admin/dashboard/request/page";
import SingleRequestsPage from "./admin/dashboard/request/[id]/page";
import NotFound from "./pages/NotFound.jsx";
import { UserProvider, useUser } from "./utils/userConfig.js";
import ProtectedRoute from "./utils/protectedRoute.js";
import Logo from "./admin/assets/images/objectindia-logo-main.png";

function AppRoutes() {
  const { loading } = useUser();

  if (loading) {
    return (
      <div className="bg-gray-500/30 w-full h-full fixed z-10 flex justify-center">
        <div className="flex items-center justify-center">
          <img
            src={Logo}
            height={120}
            width={120}
            className="mb-36 z-50 animate-bounce duration-75"
            alt="Loading"
          />
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />

      <Route
        path="/admin/dashboard"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/users"
        element={
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/users/add"
        element={
          <ProtectedRoute>
            <AddUserPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/users/:id"
        element={
          <ProtectedRoute>
            <SingleUserPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/partner"
        element={
          <ProtectedRoute>
            <PartnerPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/partner/add"
        element={
          <ProtectedRoute>
            <AddPartnerPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/partner/:id"
        element={
          <ProtectedRoute>
            <SinglePartnerPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/shops"
        element={
          <ProtectedRoute>
            <ShopsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/shops/add"
        element={
          <ProtectedRoute>
            <AddShopPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/shops/:id"
        element={
          <ProtectedRoute>
            <SingleShopPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/requests"
        element={
          <ProtectedRoute>
            <RequestsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin/dashboard/requests/:id"
        element={
          <ProtectedRoute>
            <SingleRequestsPage />
          </ProtectedRoute>
        }
      />
      <Route path="/not_found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not_found" />} />
    </Routes>
  );
}

export default function AppWithProvider() {
  return (
    <UserProvider>
      <AppRoutes />
    </UserProvider>
  );
}
