import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import adminApi from "../../../api/adminApi.js"; // Make sure this path is correct

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    userRole: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await adminApi.login(formData.email, formData.password);
      if (response.token) {
        localStorage.setItem("accessToken", response.token);
        navigate("/admin/dashboard");
        setLoading(false);
      } else {
        setLoading(false);
        setError("Invalid credentials or role");
      }
    } catch (error) {
      setLoading(false);
      console.error("Login error:", error);
      setError("Invalid credentials or role");
    }
  };

  return (
    <div className="flex items-center justify-center bg-[#151c2c] text-white text-[1.6rem] font-['Quicksand'] w-full h-full px-12 md:px-0">
      <form
        onSubmit={handleSubmit}
        className="bg-[#182237] p-20 rounded-2xl w-[600px] h-[600px] flex flex-col items-center justify-center gap-12"
      >
        <h1 className="text-white text-[3.2rem] font-bold">Login</h1>
        {error && <p className="text-red-500">{error}</p>}
        <input
          className="w-full p-12 border-[2px] border-[#2e374a] rounded-[5px] bg-[#151c2c] text-white text-[12px]"
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          className="w-full p-12 border-[2px] border-[#2e374a] rounded-[5px] bg-[#151c2c] text-white text-[12px]"
          type="password"
          placeholder="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        {!loading ? (
          <button className="w-full p-12 text-[18px] bg-[teal] text-white border-none cursor-pointer rounded-[5px] font-bold">
            Login
          </button>
        ) : (
          <div className="w-full p-12 text-[18px] bg-[teal] text-white border-none cursor-pointer rounded-[5px] font-bold">
            Please Wait ...
          </div>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
