import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout";
import adminApi from "../../../api/adminApi.js"; // Import the API
import InitialsAvatar from "react-initials-avatar";

interface User {
  _id: string; // Changed to string to match MongoDB ObjectId type
  name: string;
  email: string;
  role: string;
  phoneNumber: string;
}

const SingleUserPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = location.state?.user as User;

  const [formData, setFormData] = useState({
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phoneNumber || "",
    role: user?.role || "",
    password: "",
    currentPassword: "",
  });

  useEffect(() => {
    if (!user) {
      navigate("/admin/dashboard/users"); // Redirect if user not found
    }
  }, [user, navigate]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {

      await adminApi.updateAdmin(
        user._id,
        formData.name,
        formData.email,
        formData.phone,
        formData.password,
        formData.currentPassword
      );
      alert("User updated successfully");
      navigate("/admin/dashboard/users"); // Redirect after update
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Error updating user");
    }
  };

  return (
    <Layout>
      <div className="flex flex-col gap-12 mt-8">
        <div className="flex-1 bg-[#182237] p-8 rounded-2xl font-bold text-[#b7bac1] h-max">
          <div className="flex items-center mb-4">
            <div className="border border-black rounded-full px-4 py-3 mr-4">
              <InitialsAvatar
                className="text-xl md:text-5xl"
                name={user?.name}
              />
            </div>
            <div>
              <h3 className="text-2xl font-semibold text-white">{user.name}</h3>
              <p className="text-gray-400">{user.email}</p>
            </div>
          </div>
        </div>
        <div className="flex-[4] bg-[#182237] p-8 rounded-2xl">
          <form onSubmit={handleSubmit} className="flex flex-col text-[12px]">
            <input type="hidden" name="id" value={user._id} />
            <label htmlFor="name">Name</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="phone">Phone</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="phone"
              id="phone"
              value={formData.phone}
              onChange={handleChange}
            />
            <label htmlFor="role">Role</label>
            <select
              className="p-12 text-white mb-12 rounded-[5px] bg-[#151c2c] border-2 border-[#2e374a]"
              name="role"
              id="role"
              value={formData.role}
              onChange={handleChange}
            >
              <option value={formData.role}>{formData.role}</option>
            </select>
            <label htmlFor="password">New Password</label>
            <input
              className="p-12 text-white mb-12 rounded-[5px] bg-[#151c2c] border-2 border-[#2e374a]"
              type="password"
              name="password"
              id="password"
              placeholder="New Password"
              value={formData.password}
              onChange={handleChange}
            />
            <label htmlFor="currentPassword">Current Password</label>
            <input
              className="p-12 text-white mb-12 rounded-[5px] bg-[#151c2c] border-2 border-[#2e374a]"
              type="password"
              name="currentPassword"
              id="currentPassword"
              placeholder="Current Password"
              value={formData.currentPassword}
              onChange={handleChange}
              required
            />
            <button className="w-full p-8 bg-[teal] text-white border-none rounded-[5px] mt-8 cursor-pointer text-[16px]">
              Update
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default SingleUserPage;
