"use client";
import React from "react";
import { useLocation } from "react-router-dom";
import {
  MdMenu,
} from "react-icons/md";

interface NavbarProps {
  setSidebarShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: React.FC<NavbarProps> = ({ setSidebarShow }) => {
  const location = useLocation();
  const pathname = location.pathname;


  return (
    <div className="p-4 md:p-8 rounded-2xl bg-[#182237] flex items-center justify-between font-['Quicksand'] flex-wrap">
      <div className="w-full md:w-auto flex items-center justify-between px-2">
        <div className="text-[#b7bac1] font-bold capitalize text-[2.6rem]">
          {pathname.split("/").pop()}
        </div>
        <div className="flex md:hidden">
          <MdMenu size={20} onClick={() => setSidebarShow((prev) => !prev)} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
