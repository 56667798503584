import React, { useEffect, useState } from "react";
import Card from "../ui/dashboard/card/card";
import Layout from "./layout";
import adminApi from "../api/adminApi.js"; // Adjust the path if needed

const Dashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [shopCount, setShopCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userCount = await adminApi.getUserCount();
        const shopCount = await adminApi.getShopCount();

        setUserCount(userCount);
        setShopCount(shopCount);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const cards = [
    {
      id: 1,
      title: "Total Users",
      getNumber() {
        return userCount;
      },
      getChange() {
        // Logic for change, if applicable
        return 5; // Example change value
      },
    },
    {
      id: 2,
      title: "Total Shops",
      getNumber() {
        return shopCount;
      },
      getChange() {
        // Logic for change, if applicable
        return 5; // Example change value
      },
    },
  ];

  return (
    <Layout>
      <div className="flex gap-8 mt-8 w-full h-full">
        <div className="flex-[3] flex flex-col gap-8">
          <div className="grid grid-cols-2 lg:flex gap-8 justify-between">
            {cards.map((item) => (
              <Card item={item} key={item.id} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
