import axios from "axios";

const API_BASE_URL = "https://objectindia-backend.vercel.app/api/v1/admin"; // Adjust the base URL as needed
// const API_BASE_URL = "http://localhost:5000/api/v1/admin"; // Adjust the base URL as needed
const API_BASE_URL_NORMAL = "https://objectindia-backend.vercel.app/api/v1"; // Adjust the base URL as needed
// Axios instance with default settings
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const apiNormal = axios.create({
  baseURL: API_BASE_URL_NORMAL,
  headers: {
    "Content-Type": "application/json",
  },
});

apiNormal.interceptors.request.use((config) => {
  const token = localStorage.getItem("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Admin API Endpoints
const adminApi = {
  // Admin Authentication
  login: async (email, password) => {
    const response = await api.post("/login", { email, password });
    return response.data;
  },

  getAllCategory: async () => {
    const response = await apiNormal.get("/searchData/categories");
    return response.data;
  },

  getAllKeywords: async () => {
    const response = await apiNormal.get("/searchData/keywords");
    return response.data;
  },

  // Admin Operations
  createAdmin: async (name, email, phoneNumber, password, role) => {
    const response = await api.post("/admins/create", {
      name,
      email,
      phoneNumber,
      password,
      role,
    });
    return response.data;
  },

  getAllAdmins: async () => {
    const response = await api.get("/admins");
    return response.data;
  },

  getAdminById: async (id) => {
    const response = await api.get(`/admins/${id}`);
    return response.data;
  },

  updateAdmin: async (
    id,
    name,
    email,
    phoneNumber,
    password,
    currentPassword
  ) => {
    const response = await api.put(`/admins/${id}`, {
      name,
      email,
      phoneNumber,
      password,
      currentPassword,
    });
    return response.data;
  },

  deleteAdmin: async (id) => {
    const response = await api.delete(`/admins/${id}`);
    return response.data;
  },

  // Listing Operations
  getAllListings: async () => {
    const response = await api.get("/listings");
    return response.data;
  },

  getListingById: async (id) => {
    const response = await api.get(`/listings/${id}`);
    return response.data;
  },

  createListing: async (listingData) => {
    const response = await api.post("/listings", listingData);
    return response.data;
  },

  updateListing: async (id, listingData) => {
    const response = await api.put(`/listings/${id}`, listingData);
    if (response.status === 200) {
      return response.data;
    } else {
      const error = "Failed to full fill the request";
      return error;
    }
  },

  updateImagesPerStore: async (id, imageData) => {
    const response = await api.put(`/listings/update-image/${id}`, imageData);
    if (response.status === 200) {
      return response.data;
    } else {
      const error = "Failed to full fill the request";
      return error;
    }
  },

  deleteListing: async (id) => {
    const response = await api.delete(`/listings/${id}`);
    return response.data;
  },

  // User Operations
  getAllUsers: async () => {
    const response = await api.get("/users");
    return response.data;
  },

  getUserById: async (id) => {
    const response = await api.get(`/users/${id}`);
    return response.data;
  },

  createUser: async (userData) => {
    const response = await api.post("/users", userData);
    return response.data;
  },

  updateUser: async (id, userData) => {
    const response = await api.put(`/users/${id}`, userData);
    return response.data;
  },

  deleteUser: async (id) => {
    const response = await api.delete(`/users/${id}`);
    return response.data;
  },

  //Request Routes
  getAllRequests: async () => {
    const response = await api.get("/requests");
    return response.data;
  },

  cancelRequest: async (id) => {
    const response = await api.post(`/requests/cancel/${id}`);
    return response;
  },

  approveRequest: async (id) => {
    const response = await api.post(`/requests/approve/${id}`);
    return response;
  },

  // Misleneous Routes
  getUserCount: async () => {
    try {
      const response = await api.get("/countUser");
      return response.data.count;
    } catch (error) {
      console.error("Error fetching user count:", error);
      throw error;
    }
  },

  getShopCount: async () => {
    try {
      const response = await api.get("/countShop"); // Adjust endpoint as needed
      return response.data.count;
    } catch (error) {
      console.error("Error fetching shop count:", error);
      throw error;
    }
  },

  getMyProfile: async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) throw new Error("No access token found");

    try {
      const response = await api.get("/me");
      return response.data; // Return user data directly
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      throw new Error("Error fetching user details");
    }
  },
};

export default adminApi;
