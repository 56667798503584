import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import adminApi from "../../../api/adminApi";
import { Request } from "../../../../interface/Request";
import Layout from "../../layout";
import Logo from "../../../assets/images/objectindia-logo-main.png";
import Alert from "../../../../components/Alert";

const SingleRequestsPage: React.FC = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const requestDetails = location.state?.request as Request;
  const navigate = useNavigate();
  // Approve request handler
  const handleApprove = async () => {
    setLoading(true);
    try {
      const response = await adminApi.approveRequest(requestDetails?._id);
      if (response.status === 200) {
        navigate("/admin/dashboard/requests");
      }
    } catch (error) {
      console.error("Error approving request:", error);
      <Alert
        bg={"bg-rose-100"}
        text={"text-rose-700"}
        border={"border-rose-400"}
        message={error}
      />;
    } finally {
      setLoading(false);
    }
  };

  // Cancel request handler
  const handleCancel = async () => {
    setLoading(true);
    try {
      const response = await adminApi.cancelRequest(requestDetails?._id);
      if (response.status === 200) {
        navigate("/admin/dashboard/requests");
      }
    } catch (error) {
      console.error("Error canceling request:", error);
      <Alert
        bg={"bg-rose-100"}
        text={"text-rose-700"}
        border={"border-rose-400"}
        message={error}
      />;
      // Handle error accordingly
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-gray-500/30 w-full h-full fixed z-10 flex justify-center">
        <div className="flex items-center justify-center">
          <img
            src={Logo}
            height={120}
            width={120}
            className="mb-36 z-50 animate-bounce duration-75"
            alt="Loading"
          />
        </div>
      </div>
    );
  }

  if (!requestDetails) {
    return <div>No request details available.</div>;
  }

  return (
    <Layout>
      <div className="flex flex-col gap-12 mt-8">
        <div className="flex-1 bg-[#182237] p-8 rounded-2xl font-bold text-[#b7bac1] h-max">
          <div className="flex items-center w-full h-auto relative rounded-2xl overflow-hidden">
            {requestDetails.requestedStoreId.images[0]?.img1 ? (
              <img
                src={requestDetails.requestedStoreId.images[0]?.img1}
                alt="Shop"
                className="h-24 w-24 rounded-full object-cover mr-4"
              />
            ) : (
              <div className="h-16 w-16 rounded-full object-cover mr-4 bg-gray-500" />
            )}
            <p className="text-5xl">
              {requestDetails.requestedStoreId.shopDetails.shopName}
            </p>
          </div>
        </div>
        <div className="flex-[4] bg-[#182237] p-8 rounded-2xl">
          <form className="flex flex-col gap-12 text-[12px]">
            <input type="hidden" name="_id" value={requestDetails._id} />

            <div className="flex flex-col">
              <label className="text-4xl mb-12 font-bold underline">
                Requested User Details
              </label>
              <label htmlFor="userName">User Name</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="userName"
                id="userName"
                value={requestDetails.requestedUserId.name}
                readOnly
              />

              <label htmlFor="userEmail">User Email</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="userEmail"
                id="userEmail"
                value={requestDetails.requestedUserId.email}
                readOnly
              />

              <label htmlFor="userPhoneNumber">User Phone Number</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="userPhoneNumber"
                id="userPhoneNumber"
                value={requestDetails.requestedUserId.phoneNumber}
                readOnly
              />
            </div>

            <div className="flex flex-col">
              <label className="text-4xl mb-12 font-bold underline">
                Requested Store Details
              </label>
              {/* Owner Details */}
              <label htmlFor="ownerName">Owner Name</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="ownerName"
                id="ownerName"
                placeholder={requestDetails.requestedStoreId.ownerName}
                value={requestDetails.requestedStoreId.ownerName}
                readOnly
              />

              <label htmlFor="ownerPhoneNumber">Owner Phone Number</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="ownerPhoneNumber"
                id="ownerPhoneNumber"
                placeholder={requestDetails.requestedStoreId.ownerPhoneNumber}
                value={requestDetails.requestedStoreId.ownerPhoneNumber}
                readOnly
              />

              <label htmlFor="ownerEmail">Owner Email</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="ownerEmail"
                id="ownerEmail"
                placeholder={requestDetails.requestedStoreId.ownerEmailAddress}
                value={requestDetails.requestedStoreId.ownerEmailAddress}
                readOnly
              />

              <label htmlFor="shopName">Shop Name</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                name="shopName"
                id="shopName"
                placeholder={
                  requestDetails.requestedStoreId.shopDetails.shopName
                }
                value={requestDetails.requestedStoreId.shopDetails.shopName}
                readOnly
              />

              <label htmlFor="shopDescription">Shop Description</label>
              <textarea
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                name="shopDescription"
                id="shopDescription"
                placeholder={
                  requestDetails.requestedStoreId.shopDetails.shopDescription
                }
                value={
                  requestDetails.requestedStoreId.shopDetails.shopDescription
                }
                readOnly
              />

              <label htmlFor="businessType">Business Type</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="businessType"
                id="businessType"
                placeholder={
                  requestDetails.requestedStoreId.shopDetails.businessType
                }
                value={requestDetails.requestedStoreId.shopDetails.businessType}
                readOnly
              />

              <label htmlFor="priceRange">Price Range</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="priceRange"
                id="priceRange"
                placeholder={
                  requestDetails.requestedStoreId.shopDetails.priceRange
                }
                value={requestDetails.requestedStoreId.shopDetails.priceRange}
                readOnly
              />

              <label htmlFor="shopWebsite">Shop Website</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopWebsite"
                id="shopWebsite"
                placeholder={
                  requestDetails.requestedStoreId.shopDetails.shopWebsite
                }
                value={requestDetails.requestedStoreId.shopDetails.shopWebsite}
                readOnly
              />

              <label htmlFor="shopLocation">Shop Location</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopLocation"
                id="shopLocation"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails
                    .shopLocation
                }
                readOnly
              />

              {/* Shop Area */}
              <label htmlFor="shopArea">Shop Area</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopArea"
                id="shopArea"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails.shopArea
                }
                readOnly
              />

              {/* Shop Landmark */}
              <label htmlFor="shopLandmark">Shop Landmark</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopLandmark"
                id="shopLandmark"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails
                    .shopLandmark
                }
                readOnly
              />

              {/* Shop City */}
              <label htmlFor="shopCity">Shop City</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopCity"
                id="shopCity"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails.shopCity
                }
                readOnly
              />

              {/* Shop District */}
              <label htmlFor="shopDistrict">Shop District</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopDistrict"
                id="shopDistrict"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails
                    .shopDistrict
                }
                readOnly
              />

              {/* Shop State */}
              <label htmlFor="shopState">Shop State</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopState"
                id="shopState"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails.shopState
                }
                readOnly
              />

              {/* Shop Pincode */}
              <label htmlFor="shopPincode">Shop Pincode</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopPincode"
                id="shopPincode"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails
                    .shopPincode
                }
                readOnly
              />

              {/* Shop Building Number */}
              <label htmlFor="shopBuildingNumber">Shop Building Number</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopBuildingNumber"
                id="shopBuildingNumber"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails
                    .shopBuildingNumber
                }
                readOnly
              />

              {/* Shop Floor Number */}
              <label htmlFor="shopFloorNumber">Shop Floor Number</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="shopFloorNumber"
                id="shopFloorNumber"
                value={
                  requestDetails.requestedStoreId.shopLocationDetails
                    .shopFloorNumber
                }
                readOnly
              />

              <label htmlFor="mondayTiming">Monday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="mondayTiming"
                id="mondayTiming"
                placeholder={
                  requestDetails.requestedStoreId.shopTimingDetails.mondayTiming
                }
                value={
                  requestDetails.requestedStoreId.shopTimingDetails.mondayTiming
                }
                readOnly
              />

              {/* Repeat for other days... */}
              <label htmlFor="tuesdayTiming">Tuesday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="tuesdayTiming"
                id="tuesdayTiming"
                placeholder={
                  requestDetails.requestedStoreId.shopTimingDetails
                    .tuesdayTiming
                }
                value={
                  requestDetails.requestedStoreId.shopTimingDetails
                    .tuesdayTiming
                }
                readOnly
              />

              <label htmlFor="wednesdayTiming">Wednesday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="wednesdayTiming"
                id="wednesdayTiming"
                value={
                  requestDetails.requestedStoreId.shopTimingDetails
                    .wednesdayTiming
                }
                readOnly
              />

              <label htmlFor="thursdayTiming">Thursday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="thursdayTiming"
                id="thursdayTiming"
                value={
                  requestDetails.requestedStoreId.shopTimingDetails
                    .thursdayTiming
                }
                readOnly
              />

              <label htmlFor="fridayTiming">Friday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="fridayTiming"
                id="fridayTiming"
                value={
                  requestDetails.requestedStoreId.shopTimingDetails.fridayTiming
                }
                readOnly
              />

              <label htmlFor="saturdayTiming">Saturday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="saturdayTiming"
                id="saturdayTiming"
                value={
                  requestDetails.requestedStoreId.shopTimingDetails
                    .saturdayTiming
                }
                readOnly
              />

              <label htmlFor="sundayTiming">Sunday Timing</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="sundayTiming"
                id="sundayTiming"
                value={
                  requestDetails.requestedStoreId.shopTimingDetails.sundayTiming
                }
                readOnly
              />

              <label htmlFor="facebookLink">Facebook Link</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="facebookLink"
                id="facebookLink"
                placeholder={
                  requestDetails.requestedStoreId.shopSocialDetails.shopFacebook
                }
                value={
                  requestDetails.requestedStoreId.shopSocialDetails.shopFacebook
                }
                readOnly
              />

              <label htmlFor="instagramLink">Instagram Link</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="instagramLink"
                id="instagramLink"
                placeholder={
                  requestDetails.requestedStoreId.shopSocialDetails
                    .shopInstagram
                }
                value={
                  requestDetails.requestedStoreId.shopSocialDetails
                    .shopInstagram
                }
                readOnly
              />

              <label htmlFor="twitterLink">Twitter Link</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="twitterLink"
                id="twitterLink"
                value={
                  requestDetails.requestedStoreId.shopSocialDetails.shopTwitter
                }
                readOnly
              />
              <label htmlFor="linkedinLink">LinkedIn Link</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="linkedinLink"
                id="linkedinLink"
                value={
                  requestDetails.requestedStoreId.shopSocialDetails.shopLinkedin
                }
                readOnly
              />

              {/* YouTube Link */}
              <label htmlFor="youtubeLink">YouTube Link</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="youtubeLink"
                id="youtubeLink"
                value={
                  requestDetails.requestedStoreId.shopSocialDetails.shopYoutube
                }
                readOnly
              />

              <label htmlFor="category1">Category 1</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="category1"
                id="category1"
                value={
                  requestDetails.requestedStoreId.categoriesSelection.category1
                }
                readOnly
              />

              {/* Category 2 */}
              <label htmlFor="category2">Category 2</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="category2"
                id="category2"
                value={
                  requestDetails.requestedStoreId.categoriesSelection.category2
                }
                readOnly
              />

              {/* Category 3 */}
              <label htmlFor="category3">Category 3</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="category3"
                id="category3"
                value={
                  requestDetails.requestedStoreId.categoriesSelection.category3
                }
                readOnly
              />

              {/* Category 4 */}
              <label htmlFor="category4">Category 4</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="category4"
                id="category4"
                value={
                  requestDetails.requestedStoreId.categoriesSelection.category4
                }
                readOnly
              />

              {/* Category 5 */}
              <label htmlFor="category5">Category 5</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="category5"
                id="category5"
                value={
                  requestDetails.requestedStoreId.categoriesSelection.category5
                }
                readOnly
              />

              <label htmlFor="cash">Cash</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="cash"
                id="cash"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields.cash
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* Google Pay */}
              <label htmlFor="googlePay">Google Pay</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="googlePay"
                id="googlePay"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields
                    .googlePay
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* Paytm */}
              <label htmlFor="paytm">Paytm</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="paytm"
                id="paytm"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields.paytm
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* Phone Pay */}
              <label htmlFor="phonePay">Phone Pay</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="phonePay"
                id="phonePay"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields.phonePay
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* Cards */}
              <label htmlFor="cards">Cards</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="cards"
                id="cards"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields.cards
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* Cheque */}
              <label htmlFor="cheque">Cheque</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="cheque"
                id="cheque"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields.cheque
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* Bank Transfer */}
              <label htmlFor="bankTransfer">Bank Transfer</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="bankTransfer"
                id="bankTransfer"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields
                    .bankTransfer
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              {/* UPI */}
              <label htmlFor="upi">UPI</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="upi"
                id="upi"
                value={
                  requestDetails.requestedStoreId.paymentAcceptedFields.upi
                    ? "Yes"
                    : "No"
                }
                readOnly
              />

              <label htmlFor="mapLocation">Map Location</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="mapLocation"
                id="mapLocation"
                value={requestDetails.requestedStoreId.mapLocation}
                readOnly
              />

              {/* Tagline */}
              <label htmlFor="tagline">Tagline</label>
              <input
                className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
                type="text"
                name="tagline"
                id="tagline"
                value={requestDetails.requestedStoreId.tagline}
                readOnly
              />

              {/* Images */}
              <div className="flex gap-4">
                <img
                  src={requestDetails.requestedStoreId.images[0]?.img1}
                  alt="Store"
                  className="h-56 w-auto object-cover"
                />
                <img
                  src={requestDetails.requestedStoreId.images[0]?.img2}
                  alt="Store"
                  className="h-56 w-auto object-cover"
                />
                <img
                  src={requestDetails.requestedStoreId.images[0]?.img3}
                  alt="Store"
                  className="h-56 w-auto object-cover"
                />
              </div>
            </div>
            <div className="flex w-full mt-6">
              <button
                type="button"
                onClick={handleApprove}
                className="bg-green-500 w-full text-white text-3xl font-semibold px-4 py-2 rounded-md mr-2"
              >
                Approve
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="bg-red-500 w-full text-white text-3xl font-semibold px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default SingleRequestsPage;
