import React, { createContext, useContext, useState, useEffect } from "react";
import adminApi from "../admin/api/adminApi.js";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    try {
      const userDetails = await adminApi.getMyProfile();
      setUser(userDetails);
    } catch (error) {
      console.error("Error fetching user details:", error.message);
      setUser(null); // Set user to null if there's an error
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, fetchUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
