import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../ui/dashboard/pagination/pagination";
import Search from "../../ui/dashboard/search/search";
import Layout from "../layout";
import adminApi from "../../api/adminApi.js"; // Adjust the path if needed
import InitialsAvatar from "react-initials-avatar"; // Import the InitialsAvatar component
import { FaTrash, FaEye } from "react-icons/fa"; // Import icons for actions

interface User {
  _id: number;
  name: string;
  email: string;
  phoneNumber: string;
  role: string;
  avatar?: string;
  updatedAt: string;
  createdAt: string;
}

const UsersPage: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const ITEMS_PER_PAGE = 10;

  const location = useLocation();
  const navigate = useNavigate();

  const handleDelete = async (id: number) => {
    try {
      await adminApi.deleteAdmin(id);
      alert("User deleted successfully");
      fetchAndFilterUsers(""); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Error deleting user");
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page") || "1", 10);
    const query = params.get("q") || "";

    setCurrentPage(page);
    fetchAndFilterUsers(query);
  }, [location.search]);

  const fetchAndFilterUsers = async (query: string) => {
    try {
      const users = await adminApi.getAllAdmins();
      const filtered = users.filter((user: User) =>
        user.phoneNumber.includes(query.toLowerCase())
      );
      setFilteredUsers(filtered);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handlePageChange = (type: "prev" | "next") => {
    const params = new URLSearchParams(location.search);
    const newPage = type === "prev" ? currentPage - 1 : currentPage + 1;
    params.set("page", newPage.toString());
    navigate(`?${params.toString()}`);
  };

  const displayedUsers = filteredUsers.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  return (
    <Layout>
      <div className="bg-[#182237] h-screen p-4 mt-8 rounded-2xl md:p-8">
        <div className="flex flex-row items-center justify-between mb-4 md:mb-8">
          <Search placeholder="Search User via mobile..." />
          <Link to="/admin/dashboard/users/add" className="mt-4 md:mt-0">
            <button className="p-4 bg-[#5d57c9] text-white border-none rounded-md shadow-md hover:bg-[#4942c5]">
              Add New
            </button>
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-left text-gray-400">
            <thead className="bg-[#2d3e50] text-gray-200">
              <tr>
                <th className="py-4 px-6">Avatar</th>
                <th className="py-4 px-6">Name</th>
                <th className="py-4 px-6">Email</th>
                <th className="py-4 px-6">Phone Number</th>
                <th className="py-4 px-6">Role</th>
                <th className="py-4 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {displayedUsers.map((user) => (
                <tr
                  key={user._id}
                  className="border-b border-gray-700 hover:bg-[#2a3646]"
                >
                  <td className="py-4 px-6">
                    {user.avatar ? (
                      <img
                        src={user.avatar}
                        alt={`${user.name}'s avatar`}
                        width={40}
                        height={40}
                        className="rounded-full object-cover"
                      />
                    ) : (
                      <InitialsAvatar className="text-md" name={user?.name} />
                    )}
                  </td>
                  <td className="py-4 px-6">{user.name}</td>
                  <td className="py-4 px-6">{user.email}</td>
                  <td className="py-4 px-6">{user.phoneNumber}</td>
                  <td className="py-4 px-6 capitalize">{user.role}</td>
                  <td className="py-4 px-6 flex items-center gap-4">
                    <Link
                      to={`/admin/dashboard/users/${user._id}`}
                      state={{ user }}
                    >
                      <button className="text-teal-500 hover:text-teal-600">
                        <FaEye />
                      </button>
                    </Link>
                    <button
                      className="text-red-500 hover:text-red-600"
                      onClick={() => handleDelete(user._id)}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination
          count={filteredUsers.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default UsersPage;
