import React, { useEffect, useState, useCallback } from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "./auth.js";
import Logo from "../admin/assets/images/objectindia-logo-main.png";

const ProtectedRoute = ({ children }) => {
  const [isAuth, setIsAuth] = useState(() => {
    // Check localStorage for cached auth state
    const cachedAuth = localStorage.getItem("isAuthenticated");
    return cachedAuth === "true"; // Return true if cached, otherwise false
  });
  const [isLoading, setIsLoading] = useState(!isAuth); // Set loading based on cached state

  const checkAuth = useCallback(async () => {
    try {
      const authStatus = await isAuthenticated();
      setIsAuth(authStatus);
      localStorage.setItem("isAuthenticated", authStatus ? "true" : "false"); // Cache result
    } catch (error) {
      console.error("Authentication check failed:", error);
    } finally {
      setIsLoading(false); // Stop loading once authentication is checked
    }
  }, []);

  useEffect(() => {
    if (!isAuth) {
      checkAuth();
    }
  }, [isAuth, checkAuth]);

  if (isLoading) {
    return (
      <div className="bg-gray-500/30 w-full h-full fixed z-10 flex justify-center items-center">
        <img
          src={Logo}
          height={120}
          width={120}
          className="animate-bounce duration-75"
          alt="Loading"
        />
      </div>
    );
  }

  return isAuth ? children : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
