import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../ui/dashboard/pagination/pagination";
import Search from "../../ui/dashboard/search/search";
import Layout from "../layout";
import adminApi from "../../api/adminApi"; // Adjust the import according to your actual API file
import { FaEye, FaTrash } from "react-icons/fa";

interface Shop {
  shopDetails: {
    shopName: string;
    shopDescription: string;
    businessType: string;
    priceRange: string;
    shopWebsite: string;
  };
  shopLocationDetails: {
    shopLocation: string;
    shopArea: string;
    shopLandmark: string;
    shopCity: string;
    shopDistrict: string;
    shopState: string;
    shopPincode: string;
    shopBuildingNumber: string;
    shopFloorNumber: string;
  };
  shopSocialDetails: {
    shopFacebook: string;
    shopInstagram: string;
    shopTwitter: string;
    shopYoutube: string;
    shopLinkedin: string;
  };
  shopTimingDetails: {
    mondayTiming: string;
    tuesdayTiming: string;
    wednesdayTiming: string;
    thursdayTiming: string;
    fridayTiming: string;
    saturdayTiming: string;
    sundayTiming: string;
  };
  categoriesSelection: {
    category1: string;
    category2: string;
    category3: string;
    category4: string;
    category5: string;
  };
  paymentAcceptedFields: {
    cash: boolean;
    googlePay: boolean;
    paytm: boolean;
    phonePay: boolean;
    cards: boolean;
    cheque: boolean;
    bankTransfer: boolean;
    upi: boolean;
  };
  _id: string;
  ownerName: string;
  ownerPhoneNumber: string;
  ownerWhatsappNumber: string;
  ownerEmailAddress: string;
  mapLocation: string;
  tagline: string;
  images: Array<{
    _id: string;
    img1: string;
    img2: string;
    img3: string;
  }>;
  createdAt: string;
  updatedAt: string;
}

const ShopsPage: React.FC = () => {
  const [shops, setShops] = useState<Shop[]>([]);
  const [filteredShops, setFilteredShops] = useState<Shop[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;

  const location = useLocation();
  const navigate = useNavigate();

  const fetchShops = async () => {
    try {
      const response = await adminApi.getAllListings();
      setShops(response || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch shops", error);
      setShops([]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchShops();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = parseInt(params.get("page") || "1", 10);
    const query = params.get("q") || "";

    setCurrentPage(page);
    const filterShops = (query: string) => {
      const filtered = shops.filter((shop) =>
        shop.ownerPhoneNumber.includes(query.toLowerCase())
      );
      setFilteredShops(filtered);
    };
    if (shops.length > 0) {
      filterShops(query);
    }
  }, [location.search, shops]);

  const handlePageChange = (type: "prev" | "next") => {
    const params = new URLSearchParams(location.search);
    const newPage = type === "prev" ? currentPage - 1 : currentPage + 1;
    params.set("page", newPage.toString());
    navigate(`?${params.toString()}`);
  };

  const displayedShops = filteredShops.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleDelete = async (id: string) => {
    try {
      await adminApi.deleteListing(id);
      alert("Store deleted successfully");
      fetchShops(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting Store:", error);
      alert("Error deleting Store");
    }
  };

  return (
    <Layout>
      <div className="bg-[#182237] h-screen p-4 mt-8 rounded-2xl md:p-8">
        <div className="flex flex-row items-center justify-between mb-4 md:mb-8">
          <Search placeholder="Search for a shop..." />
          <Link to="/admin/dashboard/shops/add" className="mt-4 md:mt-0">
            <button className="p-4 bg-[#5d57c9] text-white border-none rounded-md shadow-md hover:bg-[#4942c5]">
              Add New
            </button>
          </Link>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full text-left text-gray-400">
            <thead className="bg-[#2d3e50] text-gray-200">
              <tr>
                <th className="py-4 px-6">Avatar</th>
                <th className="py-4 px-6">Shop Name</th>
                <th className="py-4 px-6">Owner</th>
                <th className="py-4 px-6">Email</th>
                <th className="py-4 px-6">Phone Number</th>
                <th className="py-4 px-6">Created At</th>
                <th className="py-4 px-6">Actions</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={7} className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : displayedShops.length === 0 ? (
                <tr>
                  <td colSpan={7} className="text-center py-4">
                    No shops found.
                  </td>
                </tr>
              ) : (
                displayedShops.map((shop) => (
                  <tr
                    key={shop._id}
                    className="border-b border-gray-700 hover:bg-[#2a3646]"
                  >
                    <td className="py-4 px-6">
                      {shop.images[0]?.img1 ? (
                        <img
                          src={shop.images[0]?.img1}
                          alt="Shop"
                          className="h-16 w-16 rounded-full object-cover mr-4"
                        />
                      ) : (
                        <div className="h-16 w-16 rounded-full object-cover mr-4 bg-gray-500" />
                      )}
                    </td>
                    <td className="py-4 px-6">{shop.shopDetails.shopName}</td>
                    <td className="py-4 px-6">{shop.ownerName}</td>
                    <td className="py-4 px-6">{shop.ownerEmailAddress}</td>
                    <td className="py-4 px-6">{shop.ownerPhoneNumber}</td>
                    <td className="py-4 px-6">
                      {new Date(shop.createdAt).toLocaleDateString()}
                    </td>
                    <td className="py-4 px-6 flex items-center gap-4">
                      <Link
                        to={`/admin/dashboard/shops/${shop._id}`}
                        state={{ shop }}
                      >
                        <button className="text-teal-500 hover:text-teal-600">
                          <FaEye />
                        </button>
                      </Link>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleDelete(shop._id);
                        }}
                      >
                        <input type="hidden" name="id" value={shop._id} />
                        <button
                          type="submit"
                          className="text-red-500 hover:text-red-600"
                        >
                          <FaTrash />
                        </button>
                      </form>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <Pagination
          count={filteredShops.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </Layout>
  );
};

export default ShopsPage;
