import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../../layout";
import adminApi from "../../../api/adminApi.js";
import imageCompression from "browser-image-compression";
import axios from "axios";
import Logo from "../../../assets/images/objectindia-logo-main.png";
import { Shop } from "../../../../interface/Shop";
import { Category } from "../../../../interface/Category";

const SingleShopPage: React.FC = () => {
  const [category, setCategory] = useState<Category[]>([]);
  const location = useLocation();
  const initialShopData = location.state?.shop as Shop;
  const [keywords, setKeywords] = useState([]);
  const [tagline, setTagline] = useState([]);
  const [formData, setFormData] = useState<Shop>({
    shopDetails: {
      shopName: initialShopData.shopDetails.shopName || "",
      shopDescription: initialShopData.shopDetails.shopDescription || "",
      businessType: initialShopData.shopDetails.businessType || "",
      priceRange: initialShopData.shopDetails.priceRange || "",
      shopWebsite: initialShopData.shopDetails.shopWebsite || "",
    },
    shopLocationDetails: {
      shopLocation: initialShopData.shopLocationDetails.shopLocation || "",
      shopArea: initialShopData.shopLocationDetails.shopArea || "",
      shopLandmark: initialShopData.shopLocationDetails.shopLandmark || "",
      shopCity: initialShopData.shopLocationDetails.shopCity || "",
      shopDistrict: initialShopData.shopLocationDetails.shopDistrict || "",
      shopState: initialShopData.shopLocationDetails.shopState || "",
      shopPincode: initialShopData.shopLocationDetails.shopPincode || "",
      shopBuildingNumber:
        initialShopData.shopLocationDetails.shopBuildingNumber || "",
      shopFloorNumber:
        initialShopData.shopLocationDetails.shopFloorNumber || "",
    },
    shopSocialDetails: {
      shopFacebook: initialShopData.shopSocialDetails.shopFacebook || "",
      shopInstagram: initialShopData.shopSocialDetails.shopInstagram || "",
      shopTwitter: initialShopData.shopSocialDetails.shopTwitter || "",
      shopYoutube: initialShopData.shopSocialDetails.shopYoutube || "",
      shopLinkedin: initialShopData.shopSocialDetails.shopLinkedin || "",
    },
    shopTimingDetails: {
      mondayTiming: initialShopData.shopTimingDetails.mondayTiming || "",
      tuesdayTiming: initialShopData.shopTimingDetails.tuesdayTiming || "",
      wednesdayTiming: initialShopData.shopTimingDetails.wednesdayTiming || "",
      thursdayTiming: initialShopData.shopTimingDetails.thursdayTiming || "",
      fridayTiming: initialShopData.shopTimingDetails.fridayTiming || "",
      saturdayTiming: initialShopData.shopTimingDetails.saturdayTiming || "",
      sundayTiming: initialShopData.shopTimingDetails.sundayTiming || "",
    },
    categoriesSelection: {
      category1: initialShopData.categoriesSelection.category1 || "",
      category2: initialShopData.categoriesSelection.category2 || "",
      category3: initialShopData.categoriesSelection.category3 || "",
      category4: initialShopData.categoriesSelection.category4 || "",
      category5: initialShopData.categoriesSelection.category5 || "",
    },
    paymentAcceptedFields: {
      cash: initialShopData.paymentAcceptedFields.cash || false,
      googlePay: initialShopData.paymentAcceptedFields.googlePay || false,
      paytm: initialShopData.paymentAcceptedFields.paytm || false,
      phonePay: initialShopData.paymentAcceptedFields.phonePay || false,
      cards: initialShopData.paymentAcceptedFields.cards || false,
      cheque: initialShopData.paymentAcceptedFields.cheque || false,
      bankTransfer: initialShopData.paymentAcceptedFields.bankTransfer || false,
      upi: initialShopData.paymentAcceptedFields.upi || false,
    },
    _id: initialShopData._id,
    ownerName: initialShopData.ownerName || "",
    ownerPhoneNumber: initialShopData.ownerPhoneNumber || "",
    ownerWhatsappNumber: initialShopData.ownerWhatsappNumber || "",
    ownerEmailAddress: initialShopData.ownerEmailAddress || "",
    mapLocation: initialShopData.mapLocation || "",
    tagline: initialShopData.tagline || "",
    images: initialShopData.images || [],
    img1: initialShopData?.images[0]?.img1,
    img2: initialShopData?.images[0]?.img2,
    img3: initialShopData?.images[0]?.img3,
    createdAt: initialShopData.createdAt,
    updatedAt: initialShopData.updatedAt,
  });
  const [loading, setloading] = useState<boolean>(false);
  const navigate = useNavigate();


  useEffect(() => {
      const fetchCategory = async () => {
        try {
          const response = await adminApi.getAllCategory();
          if (response) {
            setCategory(response);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const fetchKeywords = async () => {
        try {
          const response = await adminApi.getAllKeywords();
          if (response) {
            setKeywords(response);
          }
        } catch (error) {
          console.log(error);
        }
      };
    if (category.length === 0) {
      fetchCategory();
    }
    if (keywords.length === 0) {
      fetchKeywords();
    }
  }, [category, keywords]);

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    section: keyof Shop,
    field: string
  ) => {
    const value =
      e.target.type === "checkbox"
        ? (e.target as HTMLInputElement).checked
        : e.target.value;

    setFormData((prevData) => ({
      ...prevData,
      [section]: {
        ...(prevData[section] as Record<string, any>), // Type assertion to avoid the spread error
        [field]: value,
      },
    }));
  };

  const handleSelectedOptions = (selected: any) => {
    const newTagline = selected.map((item: any) => item.value).join(", ");

    setFormData((prevData) => ({
      ...prevData,
      tagline: prevData.tagline
        ? `${prevData.tagline}, ${newTagline}` // Append the new tagline if it already exists
        : newTagline, // Set the tagline if it's the first selection
    }));

    setTagline((prevTagline: any) =>
      prevTagline ? `${prevTagline}, ${newTagline}` : newTagline
    );
  };

  const handleChangeSingle = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    section: keyof Shop
  ) => {
    const { value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [section]: value,
    }));
  };

  const handleFileInputClick = (imageIndex: number) => {
    const inputId = `file-input-${imageIndex}`;
    const fileInput = document.getElementById(inputId) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const compressImage = async (imageFile: any) => {
    const options = {
      maxSizeMB: 0.1, // Compress to around 100KB
      maxWidthOrHeight: 800, // Maintain aspect ratio, max width/height of 800px
      useWebWorker: true, // Use web workers for faster compression
    };
    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error("Compression error:", error);
      return imageFile; // Fallback to the original file in case of an error
    }
  };

  const handleFileChange = async (e: any, imageIndex: any) => {
    const file = e.target.files[0];
    if (!file) return;
    setloading(true);
    try {
      // Compress the image before uploading
      const compressedFile = await compressImage(file);

      // Prepare FormData to send to the backend (multipart/form-data)
      const formDataImage = new FormData();
      formDataImage.append("imageField", `img${imageIndex}`);
      formDataImage.append(
        "image",
        compressedFile,
        compressedFile.name || "compressed_image.jpg"
      );

      // Send the image to the backend API with Axios
      const response = await axios.put(
        `https://objectindia-backend.vercel.app/api/v1/admin/listings/update-image/${formData._id}`,
        formDataImage,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure multipart/form-data header
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Image upload failed");
      }

      const updatedImages = response.data;

      // Update the images in the formData state
      setFormData((prevData) => {
        const updatedImagesArray = [...prevData.images];
        updatedImagesArray[imageIndex - 1] = updatedImages; // Update specific image in the array

        return {
          ...prevData,
          images: updatedImagesArray,
        };
      });
      navigate("/admin/dashboard/shops");
      alert("Image uploaded successfully");
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setloading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setloading(true);
    try {
      const {
        images,
        img1,
        img2,
        img3,
        createdAt,
        updatedAt,
        ...formDataWithoutImages
      } = formData;

      const response = await adminApi.updateListing(
        formData._id,
        formDataWithoutImages
      );
      if (response.status !== 200) {
        throw new Error("Updating Sh failed");
      }
      navigate("/admin/dashboard/shops");
    } catch (error) {
      console.log(error);
    } finally {
      setloading(false);
    }
  };

  if (!formData) {
    return <div>User not found</div>;
  }

  if (loading) {
    return (
      <div className="bg-gray-500/30 w-full h-full fixed z-10 flex justify-center">
        <div className="flex items-center justify-center">
          <img
            src={Logo}
            height={120}
            width={120}
            className="mb-36 z-50 animate-bounce duration-75"
            alt="Loading"
          />
        </div>
      </div>
    );
  }

  return (
    <Layout>
      <div className="flex flex-col gap-12 mt-8">
        <div className="flex-1 bg-[#182237] p-8 rounded-2xl font-bold text-[#b7bac1] h-max">
          <div className="flex items-center w-full h-auto relative rounded-2xl overflow-hidden">
            {formData.images[0]?.img1 ? (
              <img
                src={formData.images[0]?.img1}
                alt="Shop"
                className="h-24 w-24 rounded-full object-cover mr-4"
              />
            ) : (
              <div className="h-16 w-16 rounded-full object-cover mr-4 bg-gray-500" />
            )}
            <p className="text-5xl">{formData.shopDetails.shopName}</p>
          </div>
        </div>
        <div className="flex-[4] bg-[#182237] p-8 rounded-2xl">
          <form onSubmit={handleSubmit} className="flex flex-col text-[12px]">
            <input type="hidden" name="_id" value={formData._id} />

            {/* Owner Details */}
            <label htmlFor="ownerName">Owner Name</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="ownerName"
              id="ownerName"
              placeholder={formData.ownerName}
              value={formData.ownerName}
              onChange={(e) => handleChangeSingle(e, "ownerName")}
            />

            <label htmlFor="ownerPhoneNumber">Owner Phone Number</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="ownerPhoneNumber"
              id="ownerPhoneNumber"
              placeholder={formData.ownerPhoneNumber}
              value={formData.ownerPhoneNumber}
              onChange={(e) => handleChangeSingle(e, "ownerPhoneNumber")}
            />

            <label htmlFor="ownerWhatsappNumber">Owner Whatsapp Number</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="ownerWhatsappNumber"
              id="ownerWhatsappNumber"
              placeholder={formData.ownerWhatsappNumber}
              value={formData.ownerWhatsappNumber}
              onChange={(e) => handleChangeSingle(e, "ownerWhatsappNumber")}
            />

            <label htmlFor="ownerEmailAddress">Owner Email Address</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="email"
              name="ownerEmailAddress"
              id="ownerEmailAddress"
              placeholder={formData.ownerEmailAddress}
              value={formData.ownerEmailAddress}
              onChange={(e) => handleChangeSingle(e, "ownerEmailAddress")}
            />

            {/* Shop Details */}
            <label htmlFor="shopName">Store Name</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopName"
              id="shopName"
              placeholder={formData.shopDetails.shopName}
              value={formData.shopDetails.shopName}
              onChange={(e) => handleChange(e, "shopDetails", "shopName")}
            />

            <label htmlFor="shopDescription">Store Description</label>
            <textarea
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="shopDescription"
              id="shopDescription"
              placeholder={formData.shopDetails.shopDescription}
              value={formData.shopDetails.shopDescription}
              onChange={(e) =>
                handleChange(e, "shopDetails", "shopDescription")
              }
            />

            <label htmlFor="businessType">Business Type</label>
            <select
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="businessType"
              id="businessType"
              value={formData.shopDetails.businessType}
              onChange={(e) => handleChange(e, "shopDetails", "businessType")}
            >
              <option value="Service Provider | Selling Services">
                Service Provider | Selling Services
              </option>
              <option value="Product Supplier | selling Products">
                Product Supplier | selling Products
              </option>
              <option value="Both">Both</option>
            </select>

            <label htmlFor="priceRange">Price Range</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="priceRange"
              id="priceRange"
              placeholder={formData.shopDetails.priceRange}
              value={formData.shopDetails.priceRange}
              onChange={(e) => handleChange(e, "shopDetails", "priceRange")}
            />

            <label htmlFor="shopWebsite">Website</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopWebsite"
              id="shopWebsite"
              placeholder={formData.shopDetails.shopWebsite}
              value={formData.shopDetails.shopWebsite}
              onChange={(e) => handleChange(e, "shopDetails", "shopWebsite")}
            />

            {/* Shop Location Details */}
            <label htmlFor="shopLocation">Address</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopLocation"
              id="shopLocation"
              placeholder={formData.shopLocationDetails.shopLocation}
              value={formData.shopLocationDetails.shopLocation}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopLocation")
              }
            />

            <label htmlFor="shopArea">Area</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopArea"
              id="shopArea"
              placeholder={formData.shopLocationDetails.shopArea}
              value={formData.shopLocationDetails.shopArea}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopArea")
              }
            />
            <label htmlFor="shopLandmark">Landmark</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopLandmark"
              id="shopLandmark"
              placeholder={formData.shopLocationDetails.shopLandmark}
              value={formData.shopLocationDetails.shopLandmark}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopLandmark")
              }
            />
            <label htmlFor="shopCity">City</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopCity"
              id="shopCity"
              placeholder={formData.shopLocationDetails.shopCity}
              value={formData.shopLocationDetails.shopCity}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopCity")
              }
            />
            <label htmlFor="shopDistrict">District</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopDistrict"
              id="shopDistrict"
              placeholder={formData.shopLocationDetails.shopDistrict}
              value={formData.shopLocationDetails.shopDistrict}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopDistrict")
              }
            />
            <label htmlFor="shopState">State</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopState"
              id="shopState"
              placeholder={formData.shopLocationDetails.shopState}
              value={formData.shopLocationDetails.shopState}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopState")
              }
            />
            <label htmlFor="shopPincode">Pincode</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopPincode"
              id="shopPincode"
              placeholder={formData.shopLocationDetails.shopPincode}
              value={formData.shopLocationDetails.shopPincode}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopPincode")
              }
            />
            <label htmlFor="shopBuildingNumber">Building Number</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopBuildingNumber"
              id="shopBuildingNumber"
              placeholder={formData.shopLocationDetails.shopBuildingNumber}
              value={formData.shopLocationDetails.shopBuildingNumber}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopBuildingNumber")
              }
            />
            <label htmlFor="shopFloorNumber">Floor Number</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopFloorNumber"
              id="shopFloorNumber"
              placeholder={formData.shopLocationDetails.shopFloorNumber}
              value={formData.shopLocationDetails.shopFloorNumber}
              onChange={(e) =>
                handleChange(e, "shopLocationDetails", "shopFloorNumber")
              }
            />

            {/*Shop Social Details */}

            <label htmlFor="shopFacebook">Facebook</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopFacebook"
              id="shopFacebook"
              placeholder={formData.shopSocialDetails.shopFacebook}
              value={formData.shopSocialDetails.shopFacebook}
              onChange={(e) =>
                handleChange(e, "shopSocialDetails", "shopFacebook")
              }
            />
            <label htmlFor="shopInstagram">Instagram</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopInstagram"
              id="shopInstagram"
              placeholder={formData.shopSocialDetails.shopInstagram}
              value={formData.shopSocialDetails.shopInstagram}
              onChange={(e) =>
                handleChange(e, "shopSocialDetails", "shopInstagram")
              }
            />
            <label htmlFor="shopTwitter">Twitter</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopTwitter"
              id="shopTwitter"
              placeholder={formData.shopSocialDetails.shopTwitter}
              value={formData.shopSocialDetails.shopTwitter}
              onChange={(e) =>
                handleChange(e, "shopSocialDetails", "shopTwitter")
              }
            />
            <label htmlFor="shopYoutube">Youtube</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopYoutube"
              id="shopYoutube"
              placeholder={formData.shopSocialDetails.shopYoutube}
              value={formData.shopSocialDetails.shopYoutube}
              onChange={(e) =>
                handleChange(e, "shopSocialDetails", "shopYoutube")
              }
            />
            <label htmlFor="shopLinkedin">Linkedin</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="shopLinkedin"
              id="shopLinkedin"
              placeholder={formData.shopSocialDetails.shopLinkedin}
              value={formData.shopSocialDetails.shopLinkedin}
              onChange={(e) =>
                handleChange(e, "shopSocialDetails", "shopLinkedin")
              }
            />

            {/* Shop Timings Details */}
            <label htmlFor="mondayTiming">Monday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="mondayTiming"
              id="mondayTiming"
              placeholder={formData.shopTimingDetails.mondayTiming}
              value={formData.shopTimingDetails.mondayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "mondayTiming")
              }
            />
            <label htmlFor="tuesdayTiming">Tuesday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="tuesdayTiming"
              id="tuesdayTiming"
              placeholder={formData.shopTimingDetails.tuesdayTiming}
              value={formData.shopTimingDetails.tuesdayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "tuesdayTiming")
              }
            />
            <label htmlFor="wednesdayTiming">Wednesday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="wednesdayTiming"
              id="wednesdayTiming"
              placeholder={formData.shopTimingDetails.wednesdayTiming}
              value={formData.shopTimingDetails.wednesdayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "wednesdayTiming")
              }
            />
            <label htmlFor="thursdayTiming">Thursday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="thursdayTiming"
              id="thursdayTiming"
              placeholder={formData.shopTimingDetails.thursdayTiming}
              value={formData.shopTimingDetails.thursdayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "thursdayTiming")
              }
            />
            <label htmlFor="fridayTiming">Friday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="fridayTiming"
              id="fridayTiming"
              placeholder={formData.shopTimingDetails.fridayTiming}
              value={formData.shopTimingDetails.fridayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "fridayTiming")
              }
            />
            <label htmlFor="saturdayTiming">Saturday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="saturdayTiming"
              id="saturdayTiming"
              placeholder={formData.shopTimingDetails.saturdayTiming}
              value={formData.shopTimingDetails.saturdayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "saturdayTiming")
              }
            />
            <label htmlFor="sundayTiming">Sunday</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="sundayTiming"
              id="sundayTiming"
              placeholder={formData.shopTimingDetails.sundayTiming}
              value={formData.shopTimingDetails.sundayTiming}
              onChange={(e) =>
                handleChange(e, "shopTimingDetails", "sundayTiming")
              }
            />

            {/* Shop Category Details */}

            <label htmlFor="category1">Category 1</label>
            <select
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="category1"
              id="category1"
              value={formData.categoriesSelection.category1} // Using the category from formData
              onChange={(e) =>
                handleChange(e, "categoriesSelection", "category1")
              }
            >
              <option value="" disabled>
                Select a category
              </option>{" "}
              {/* Optional placeholder */}
              {category.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <label htmlFor="category2">Category 2</label>
            <select
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="category2"
              id="category2"
              value={formData.categoriesSelection.category2}
              onChange={(e) =>
                handleChange(e, "categoriesSelection", "category2")
              }
            >
              <option value="" disabled>
                Select a category
              </option>{" "}
              {/* Optional placeholder */}
              {category.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <label htmlFor="category3">Category 3</label>
            <select
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="category3"
              id="category3"
              value={formData.categoriesSelection.category3}
              onChange={(e) =>
                handleChange(e, "categoriesSelection", "category3")
              }
            >
              <option value="" disabled>
                Select a category
              </option>{" "}
              {/* Optional placeholder */}
              {category.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <label htmlFor="category4">Category 4</label>
            <select
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="category4"
              id="category4"
              value={formData.categoriesSelection.category4}
              onChange={(e) =>
                handleChange(e, "categoriesSelection", "category4")
              }
            >
              <option value="" disabled>
                Select a category
              </option>{" "}
              {/* Optional placeholder */}
              {category.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <label htmlFor="category5">Category 5</label>
            <select
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              name="category5"
              id="category5"
              value={formData.categoriesSelection.category5}
              onChange={(e) =>
                handleChange(e, "categoriesSelection", "category5")
              }
            >
              <option value="" disabled>
                Select a category
              </option>{" "}
              {/* Optional placeholder */}
              {category.map((item) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            {/* Shop Payment Details */}

            <label>Payment Accepted Fields</label>
            <div className="flex flex-row gap-5 text-2xl">
              <label htmlFor="cash" className="flex items-center">
                <input
                  type="checkbox"
                  name="cash"
                  id="cash"
                  checked={formData.paymentAcceptedFields.cash}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "cash")
                  }
                  className="mr-2"
                />
                Cash
              </label>
              <label htmlFor="googlePay" className="flex items-center">
                <input
                  type="checkbox"
                  name="googlePay"
                  id="googlePay"
                  checked={formData.paymentAcceptedFields.googlePay}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "googlePay")
                  }
                  className="mr-2"
                />
                Google Pay
              </label>
              <label htmlFor="paytm" className="flex items-center">
                <input
                  type="checkbox"
                  name="paytm"
                  id="paytm"
                  checked={formData.paymentAcceptedFields.paytm}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "paytm")
                  }
                  className="mr-2"
                />
                Paytm
              </label>
              <label htmlFor="phonePay" className="flex items-center">
                <input
                  type="checkbox"
                  name="phonePay"
                  id="phonePay"
                  checked={formData.paymentAcceptedFields.phonePay}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "phonePay")
                  }
                  className="mr-2"
                />
                Phone Pay
              </label>
              <label htmlFor="cards" className="flex items-center">
                <input
                  type="checkbox"
                  name="cards"
                  id="cards"
                  checked={formData.paymentAcceptedFields.cards}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "cards")
                  }
                  className="mr-2"
                />
                Cards
              </label>
              <label htmlFor="cheque" className="flex items-center">
                <input
                  type="checkbox"
                  name="cheque"
                  id="cheque"
                  checked={formData.paymentAcceptedFields.cheque}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "cheque")
                  }
                  className="mr-2"
                />
                Cheque
              </label>
              <label htmlFor="bankTransfer" className="flex items-center">
                <input
                  type="checkbox"
                  name="bankTransfer"
                  id="bankTransfer"
                  checked={formData.paymentAcceptedFields.bankTransfer}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "bankTransfer")
                  }
                  className="mr-2"
                />
                Bank Transfer
              </label>
              <label htmlFor="upi" className="flex items-center">
                <input
                  type="checkbox"
                  name="upi"
                  id="upi"
                  checked={formData.paymentAcceptedFields.upi}
                  onChange={(e) =>
                    handleChange(e, "paymentAcceptedFields", "upi")
                  }
                  className="mr-2"
                />
                UPI
              </label>
            </div>

            <label htmlFor="mapLocation">Map Location</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="mapLocation"
              id="mapLocation"
              placeholder={formData.mapLocation}
              value={formData.mapLocation}
              onChange={(e) => handleChangeSingle(e, "mapLocation")}
            />

            <label htmlFor="tagline">Tagline</label>
            <input
              className="p-8 my-4 rounded-[5px] text-white bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              type="text"
              name="tagline"
              id="tagline"
              placeholder={formData.tagline}
              value={formData.tagline}
              onChange={(e) => handleChangeSingle(e, "tagline")}
              readOnly
            />

            <Select
              isMulti
              options={keywords}
              value={tagline}
              onChange={handleSelectedOptions}
              className="p-8 my-4 rounded-[5px] text-black bg-[#151c2c] border-2 border-[#2e374a] text-[16px]"
              classNamePrefix="select"
            />

            <div className="flex flex-col">
              <label>Shop Images</label>

              {/* Image 1 */}
              <div className="flex items-center my-4">
                <img
                  src={formData.images[0]?.img1}
                  alt="Shop 1"
                  className="w-[100px] h-[100px] object-cover mr-4 rounded-md border-2 border-gray-400"
                />
                <button
                  type="button"
                  className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 ml-2"
                  onClick={() => handleFileInputClick(1)}
                >
                  Upload Image 1
                </button>

                <input
                  id="file-input-1"
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, 1)}
                />
              </div>

              {/* Image 2 */}
              <div className="flex items-center my-4">
                <img
                  src={formData.images[0]?.img2}
                  alt="Shop 2"
                  className="w-[100px] h-[100px] object-cover mr-4 rounded-md border-2 border-gray-400"
                />
                <button
                  type="button"
                  className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 ml-2"
                  onClick={() => handleFileInputClick(2)}
                >
                  Upload Image 2
                </button>

                <input
                  id="file-input-2"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, 2)}
                />
              </div>

              {/* Image 3 */}
              <div className="flex items-center my-4">
                <img
                  src={formData.images[0]?.img3}
                  alt="Shop 3"
                  className="w-[100px] h-[100px] object-cover mr-4 rounded-md border-2 border-gray-400"
                />
                <button
                  type="button"
                  className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 ml-2"
                  onClick={() => handleFileInputClick(3)}
                >
                  Upload Image 3
                </button>

                <input
                  id="file-input-3"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, 3)}
                />
              </div>
            </div>

            <button
              type="submit"
              className="p-8 my-4 bg-blue-600 text-white rounded-[5px] text-[16px] hover:bg-blue-700"
            >
              Save Changes
            </button>
          </form>
          {/* <button id={styles.editButton}>Edit</button> */}
        </div>
      </div>
    </Layout>
  );
};

export default SingleShopPage;
