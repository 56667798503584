import React from "react";
import Navbar from "../ui/dashboard/navbar/navbar";
import Sidebar from "../ui/dashboard/sidebar/sidebar";
import Footer from "../ui/dashboard/footer/footer";
import { useEffect, useState } from "react";
import { useUser } from "../../utils/userConfig.js";

type Props = {
  children: React.ReactNode;
};

function Layout({ children }: Props): JSX.Element {
  const [sidebarShow, setSidebarShow] = useState<boolean>(true);
  const { user } = useUser();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarShow(false);
      } else {
        setSidebarShow(true);
      }
    };

    // Set initial state
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex bg-[rgb(21,28,44)] h-full text-white text-[1.6rem] font-['Quicksand']">
      {sidebarShow && (
        <div className="sticky md:w-auto bg-[#182237] p-8 min-h-[100vh] z-10">
          <Sidebar user={user} />
        </div>
      )}

      <div className="flex flex-col p-8 z-0 w-full">
        <Navbar setSidebarShow={setSidebarShow} />
        {children}
        <Footer />
      </div>
    </div>
  );
}

export default Layout;
